import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';
import mixpanelPlugin from '@analytics/mixpanel';
import { merge } from 'lodash';

const RESOLVE_IP_TIMEOUT = 3_000;

function enrichWithIp(userConfig) {
  let ip = 'Unknown';
  let isLoaded = false;
  /** @type number */
  let ipFetchStart;

  const addIpToProperties = ({ payload }) => {
    if (typeof window === `undefined`) return;

    return merge(payload, {
      properties: {
        userIp: ip
      }
    });
  };

  // return object for analytics to use
  return {
    /* All plugins require a name */
    name: 'enrich-with-ip',
    initialize: () => {
      if (typeof window === `undefined`) return;

      ipFetchStart = Date.now();
      fetch(
          'https://api.minware.com/api/ip',
          { signal: AbortSignal.timeout(5000) }
        )
        .then(response => response.json())
        .then(ipResponse => { ip = ipResponse.ip; })
        .catch(() => {} /** ignored */)
        .finally(() => { isLoaded = true; });
    },
    loaded: () => {
      return isLoaded || (Date.now() - ipFetchStart > RESOLVE_IP_TIMEOUT);
    },
    pageStart: addIpToProperties,
    trackStart: addIpToProperties,
  }
}
const waitForDocumentReadyPlugin = {
  name: 'waitForDocumentReady',
  /* Hook into initializeStart. This is before third party scripts have loaded on the page */
  async initializeStart({ abort, config }) {
    if (
      typeof window !== 'undefined' && typeof document !== 'undefined'
      && document.readyState !== 'complete'
    ) {
      return new Promise(resolve => {
        window.addEventListener('load', () => {
          resolve(true)
        });
      });
    }
    return true;
  },
  loaded() {
    return typeof document !== 'undefined' && document.readyState === 'complete';
  },
};

const analytics = Analytics({
  app: 'minware-www',
  /** enable this to see analytics in the redux devtools */
  //debug: true,
  plugins: [
    waitForDocumentReadyPlugin,
    enrichWithIp(),
    // These are for production - TODO: use different tokens for staging/dev
    googleTagManager({
      containerId: 'GTM-W7VWQDQ',
    }),
    mixpanelPlugin({
      token: '7998a44309da6bee088d922e72d2eef3',
      customScriptSrc: 'https://app.minware.com/prx/mp/lib.min.js',
      options: {
        api_host: 'https://app.minware.com/prx/mp',
        ignore_dnt: true,
      }
    }),
  ],
});

// Set to global so analytics plugin will work with Gatsby
if (typeof window !== 'undefined') {
  window.Analytics = analytics;
}

export function track(...args) {
  analytics.ready(() => {
    analytics.track(...args);
  });
}

export function page(...args) {
  analytics.ready(() => {
    analytics.page(...args);
  });
}

// Export for consumption in your components for .track & .identify calls
export default analytics;
// We need to do this because we wrap our calls in ready and if ready isn't called before the ready
// event is fired the internal state in the analytics packages is not updated
analytics.ready(() => {});
